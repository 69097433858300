import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_USER,
  FETCH_BRANCH,
  FETCH_BRANCH_RATE,
  FETCH_BRANCH_SEARCH_USER,
  FETCH_BRANCH_SELECT_OPTIONS,
  FETCH_BRANCH_ORGANIZATION_CHART,
  FETCH_BRANCH_ORGANIZATION_CHART_SUB,
  FETCH_BRANCH_WITHDRAWABLE_DAY,
  FETCH_BALANCE_BRANCH_DAILY_PROFIT,
  FETCH_BRANCH_RECOMMEND_CODE,
  FETCH_BALANCE_ROLLING,
  FETCH_BRANCH_BALANCE_GROUP,

  UPDATE_STATUS_COMMISSION,
} from '@/store/branch/action'

import {
  UPSERT_BRANCH,
  CREATE_BRANCH_CASHOUT_REQUEST,
} from '@/store/branch/mutation'
import * as moment from 'moment-timezone'

import buildTree from './common'

moment().tz('Asia/Seoul')

const branchStore = {
  namespaced: true,
  state: {
    fetchUser: false,
    fetchBranch: {},
    fetchBranchRate: [],
    fetchBranchSearchUser: [],
    fetchBranchSelectOptions: [],
    fetchBranchOrganizationChart: {},
    fetchBranchOrganizationChartSub: [],
    fetchBranchWithdrawableDay: '',
    fetchBalanceBranchDailyProfit: [],
    fetchBranchRecommendCode: null,
    fetchBalanceRolling: [],
    fetchBranchBalanceGroup: [],
    upsertBranch: [],
  },
  getters: {
    fetchUser: state => state.fetchUser,
    fetchBranch: state => state.fetchBranch,
    fetchBranchRate: state => state.fetchBranchRate,
    fetchBranchSearchUser: state => state.fetchBranchSearchUser,
    fetchBranchSelectOptions: state => state.fetchBranchSelectOptions,
    fetchBranchOrganizationChart: state => state.fetchBranchOrganizationChart,
    fetchBranchOrganizationChartSub: state => state.fetchBranchOrganizationChartSub,
    fetchBranchWithdrawableDay: state => state.fetchBranchWithdrawableDay,
    fetchBalanceBranchDailyProfit: state => state.fetchBalanceBranchDailyProfit,
    fetchBranchRecommendCode: state => state.fetchBranchRecommendCode,
    fetchBalanceRolling: state => state.fetchBalanceRolling,
    fetchBranchBalanceGroup: state => state.fetchBranchBalanceGroup,
  },
  mutations: {
    [FETCH_USER]: (state, payload) => { state.fetchUser = !payload },
    [FETCH_BRANCH]: (state, payload) => { state.fetchBranch = payload },
    [FETCH_BRANCH_RATE]: (state, payload) => { state.fetchBranchRate = payload },
    [FETCH_BRANCH_SEARCH_USER]: (state, payload) => { state.fetchBranchSearchUser = payload },
    [FETCH_BRANCH_SELECT_OPTIONS]: (state, payload) => { state.fetchBranchSelectOptions = payload },
    [FETCH_BRANCH_ORGANIZATION_CHART]: (state, payload) => { state.fetchBranchOrganizationChart = payload },
    [FETCH_BRANCH_ORGANIZATION_CHART_SUB]: (state, payload) => { state.fetchBranchOrganizationChartSub = payload },
    [FETCH_BRANCH_WITHDRAWABLE_DAY]: (state, payload) => { state.fetchBranchWithdrawableDay = payload },
    [FETCH_BALANCE_BRANCH_DAILY_PROFIT]: (state, payload) => { state.fetchBalanceBranchDailyProfit = payload },
    [FETCH_BRANCH_RECOMMEND_CODE]: (state, payload) => { state.fetchBranchRecommendCode = payload },
    [FETCH_BALANCE_ROLLING]: (state, payload) => { state.fetchBalanceRolling = payload },
    [FETCH_BRANCH_BALANCE_GROUP]: (state, payload) => { state.fetchBranchBalanceGroup = payload },
    updateParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      state[events.t][events.e] = events.v
    },
  },
  actions: {
    async [FETCH_USER]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query chkUserInfo($data: UsersInput!) {
            chkUserInfo(data: $data) 
          }
        `,
        variables: {
          data: {
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      console.log('[FETCH_USER]', data.chkUserInfo)
      commit(FETCH_USER, data.chkUserInfo)
    },

    async [FETCH_BRANCH]({ commit }, payload) {
      // console.log('[FETCH_BRANCH]', payload)

      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranch($where: BranchInput!) {
            fetchBranch(where: $where) {
              site
              userid
              username
              nickname
              recommendNickname
              recommendCode
              email
              mobile
              rank
              role
              status
              rate
              rateSportRolling
              rateCasinoRolling
              rateSlotRolling
              withdrawableDay
              userAccountInfo {
                userAccountBank
                userAccountNumber
                userAccountName
              }
              branchAccountInfo {
                adminAccountBank
                adminAccountNumber
                adminAccountName
              }
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BRANCH, data.fetchBranch)
      // console.log('[FETCH_BRANCH]', data.fetchBranch)
    },

    async [FETCH_BRANCH_RATE]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchRate($where: BranchInput!) {
            fetchBranchRate(where: $where) {
              site
              userid
              username
              nickname
              recommendNickname
              role
              authSite
              rate
              rateSportRolling
              rateCasinoRolling
              rateSlotRolling
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BRANCH_RATE, data.fetchBranchRate)
    },

    async [FETCH_BRANCH_SELECT_OPTIONS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
        query fetchBranchSelectOptions($where: BranchInput!) {
          fetchBranchSelectOptions(where: $where) {
            site
            userid
            username
            nickname
            branch
            recommendNickname
            rank
            role
            status
          }
        }
      `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BRANCH_SELECT_OPTIONS, data.fetchBranchSelectOptions)
    },

    /* 총판관리 > 총판관리 > 조직도 */
    async [FETCH_BRANCH_ORGANIZATION_CHART]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchOrganizationChart($where: BranchInput!) {
            fetchBranchOrganizationChart(where: $where) {
              site
              userid
              username
              nickname
              recommendNickname
              role
              status
              authSite
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      const buildData = buildTree(data.fetchBranchOrganizationChart)
      commit(FETCH_BRANCH_ORGANIZATION_CHART, buildData)
    },

    /* 총판관리 > 총판관리 > 조직도(상세) */
    async [FETCH_BRANCH_ORGANIZATION_CHART_SUB]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchOrganizationChartSub($where: BranchInput!) {
            fetchBranchOrganizationChartSub(where: $where) {
              site
              userid
              username
              nickname
              recommendNickname
              role
              status
              authSite
              cash
              point
              rate
              upperRate
              rateSportRolling
              upperRateSportRolling
              rateCasinoRolling
              upperRateCasinoRolling
              rateSlotRolling
              upperRateSlotRolling
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      console.log(data.fetchBranchOrganizationChartSub)
      const buildData = buildTree(data.fetchBranchOrganizationChartSub)
      commit(FETCH_BRANCH_ORGANIZATION_CHART_SUB, buildData)
    },

    async [FETCH_BRANCH_SEARCH_USER]({ commit }, payload) {
      // console.log('[FETCH_BRANCH_SEARCH_USER]', payload)
      const where = {
        site: payload.site,
        userid: payload.userid,
      }

      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchSearchUser($where: BranchInput!) {
            fetchBranchSearchUser(where: $where){
              site
              userid
              username
              nickname
              recommendNickname
              email
              mobile
              role
              status
              isRoleUp
            }
          }
        `,
        variables: {
          where,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BRANCH_SEARCH_USER, data.fetchBranchSearchUser)
    },

    async [FETCH_BRANCH_WITHDRAWABLE_DAY]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchWithdrawableDay($where: BranchInput!) {
            fetchBranchWithdrawableDay(where: $where) 
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      console.log('FETCH_BRANCH_WITHDRAWABLE_DAY', data)
      commit(FETCH_BRANCH_WITHDRAWABLE_DAY, data.fetchBranchWithdrawableDay)
    },
    async [FETCH_BALANCE_BRANCH_DAILY_PROFIT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBranchDailyProfit($where: BranchInput!) {
            fetchBranchDailyProfit(where: $where) {
              idx
              baseDate
              site
              branch
              deposit
              lowerDeposit
              exchange
              lowerExchange
              curCash
              curLowerCash
              curPoint
              curLowerPoint
              rate
              totalSportBettingAmount
              totalSportRollingAmount
              totalCasinoBettingAmount
              totalCasinoRollingAmount
              totalSlotBettingAmount
              totalSlotRollingAmount
              profit
              accLowerProfit
              sumProfit
              preAccProfit
              accProfit
              withdrawableAmount
              withdrawnAmount
              postWithdrawalAmount
              type
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
            fromAt: payload.fromAt,
            toAt: payload.toAt,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BALANCE_BRANCH_DAILY_PROFIT, data.fetchBranchDailyProfit)
    },
    async [FETCH_BRANCH_RECOMMEND_CODE]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query {
            fetchBranchRecommendCode
          }
        `,
        fetchPolicy: 'no-cache',
      })
      // commit(FETCH_BRANCH_RECOMMEND_CODE, data.fetchBranchRecommendCode)
      return data.fetchBranchRecommendCode
    },
    async [FETCH_BALANCE_ROLLING]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchBalanceRolling($where: BranchInput!) {
            fetchBalanceRolling(where: $where) {
              idx
              baseDate
              issueDate
              site
              seq
              userid
              sportBettingAmount
              rateSportRolling
              sportRollingAmount
              casinoBettingAmount
              rateCasinoRolling
              casinoRollingAmount
              slotBettingAmount
              rateSlotRolling
              slotRollingAmount
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            branch: payload.branch,
            fromAt: payload.fromAt,
            toAt: payload.toAt,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BALANCE_ROLLING, data.fetchBalanceRolling)
    },

    async [FETCH_BRANCH_BALANCE_GROUP]({ commit }, payload) {
      const fetchBranchBalanceGroup = await apolloClient.query({
        query: gql`
          query fetchBranchBalanceGroup($where: BranchInput!) {
            fetchBranchBalanceGroup(where: $where) {
              branch
              deposit
              lowerDeposit
              exchange
              lowerExchange
              curCash
              curLowerCash
              curPoint
              curLowerPoint
              rate
              totalSportBettingAmount
              totalSportRollingAmount
              totalCasinoBettingAmount
              totalCasinoRollingAmount
              totalSlotBettingAmount
              totalSlotRollingAmount
              profit
              accLowerProfit
              sumProfit
              preAccProfit
              accProfit
              withdrawableAmount
              withdrawnAmount
              postWithdrawalAmount
            }
          }
        `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
            fromAt: payload.fromAt,
            toAt: payload.toAt,
          },
        },
        fetchPolicy: 'no-cache',
      })
      // console.log('[APOllO] [FETCH_BRANCH_BALANCE_GROUP]...', JSON.stringify(fetchBranchBalanceGroup.data.fetchBranchBalanceGroup))
      commit(FETCH_BRANCH_BALANCE_GROUP, fetchBranchBalanceGroup.data.fetchBranchBalanceGroup)
    },

    /* 총판 등록관리 */
    async [UPSERT_BRANCH]({ commit }, payload) {
      console.log('[BranchStore] [ACTIONS] [UPSERT_BRANCH]... payload: \n', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
            mutation upsertBranch($where: BranchUpsertInput!){
              upsertBranch(where: $where){
                status
                messages
              }
            }
          `,
        variables: {
          where: {
            site: payload.site,
            userid: payload.userid,
            username: payload.username,
            nickname: payload.nickname,
            recommendNickname: payload.recommendNickname,
            recommendCode: payload.recommendCode,
            email: payload.email,
            mobile: payload.mobile,
            rank: Number(payload.rank),
            // calculatePeriod: payload.period 총판 정산 주기
            role: payload.role,
            status: payload.status,
            rate: Number(payload.rate),
            authSite: payload.authSite,
            rateSportRolling: Number(payload.rateSportRolling),
            rateCasinoRolling: Number(payload.rateCasinoRolling),
            rateSlotRolling: Number(payload.rateSlotRolling),
            withdrawableDay: payload.withdrawableDay,
            userAccountInfo: {
              userAccountBank: payload.userAccountInfo.userAccountBank,
              userAccountNumber: payload.userAccountInfo.userAccountNumber,
              userAccountName: payload.userAccountInfo.userAccountName,
              userAccountOutcomePassword: payload.userAccountInfo.userAccountOutcomePassword,
              adminAccountBank: payload.userAccountInfo.adminAccountBank,
              adminAccountNumber: payload.userAccountInfo.adminAccountNumber,
              adminAccountName: payload.userAccountInfo.adminAccountName,
            },
            branchAccountInfo: {
              adminAccountBank: payload.branchAccountInfo.adminAccountBank,
              adminAccountNumber: payload.branchAccountInfo.adminAccountNumber,
              adminAccountName: payload.branchAccountInfo.adminAccountName,
            },
          },
        },
        fetchPolicy: 'no-cache',
      })
      console.log('[BranchStore] [upsertBranch]...', data)
    },

    async [UPDATE_STATUS_COMMISSION]({ commit }, payload) {
      // console.log('[BranchStore] [UPDATE_STATUS_COMMISSION]...', payload)
      try {
        await apolloClient.mutate({
          mutation: gql`
              mutation($branchInput: BranchInput!) {
                updateStatusCommission(branchInput: $branchInput)
            }`,
          variables: {
            branchInput: {
              idx: payload.idx,
              type: 'ok',
            },
          },
        })
        // console.log( data)
      } catch (error) {
        console.log(error.message)
        // this.logger.error(error.message)
        throw error
      }
      commit(UPDATE_STATUS_COMMISSION, payload)
    },

    // 출금신청
    async [CREATE_BRANCH_CASHOUT_REQUEST]({ commit }, payload) {
      // console.log('update payload : ', JSON.stringify(payload))
      // console.log('[CREATE_BRANCH_CASHOUT_REQUEST] data: ', JSON.stringify(data))
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userInfo = {
        userid: userData.userid,
        branch: userData.role === 'admin' ? 'admin' : userData.branch,
        site: userData.site,
        ip: localStorage.getItem('ip'),
        pId: `E00${Date.now()}`,
      }

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createBranchCashoutRequest($requestAlarmsBranchCashoutInputArgs: RequestAlarmsBranchCashoutInputArgs!, $ip: String!, $userid: String!){
            createBranchCashoutRequest(requestAlarmsBranchCashoutInputArgs: $requestAlarmsBranchCashoutInputArgs, ip: $ip, userid: $userid) 
          }
        `,
        variables: {
          requestAlarmsBranchCashoutInputArgs: {
            site: payload.site,
            category: 'CASHOUT',
            requestId: userInfo.userid,
            requestCash: 100, // payload.requestCash,
            requestIp: userInfo.ip,
            requsetMemo: payload.requestMemo,
            paymentId: userInfo.pId,
            uid: `${userInfo.site}:${userInfo.userid}:${payload.requestCash}:${moment(new Date()).format('YYYYMMDD')}`,
          },
          ip: userInfo.ip,
          userid: userInfo.userid,
        },
        fetchPolicy: 'no-cache',
      })
      // commit(CREATE_BRANCH_CASHOUT_REQUEST, data.updateManyBranchCashoutConfirm)
      console.log('CREATE_BRANCH_CASHOUT_REQUEST : ', data)
    },
  },
}

export default branchStore
