import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { FetchUserBetHistoryDocument } from '@/generated/graphql'
import {
  FETCH_USER, FETCH_USERS,

  FETCH_USER_SINGLE,
  FETCH_USER_LIST_MULTIPLE,

  FETCH_USER_BET_HISTORY,
  FETCH_USER_UPDATE_LOGS,
} from '@/store/users/action'
import { UPDATE_USER, UPDATE_USERS_PASSWORD, UPDATE_USERS_ASSET, UPDATE_USERS_OUTCOME_PASSWORD } from '@/store/users/mutation'
// import {  } from './mutation'

const userStore = {
  namespaced: true,
  state: {

    fetchUser: {},
    fetchUsers: [],

    userBetHistory: [{}],
    updateUsers: [],
    updateUsersPassword: [],
    updateUsersOutcomePassword: [],

    fetchUserUpdateLogs: [],
  },
  getters: {
    // User
    user: state => state.fetchUser,
    fetchUser: state => state.fetchUser,
    fetchUsers: state => state.fetchUsers,

    updateUsers: state => state.users[0],
    updateUsersPassword: state => state.updateUsersPassword[0],
    updateUsersOutcomePassword: state => state.updateUsersOutcomePassword[0],

    // UserBetHistory
    userBetHistory: state => state.userBetHistory,

    // UserUpdateLogs
    fetchUserUpdateLogs: state => state.fetchUserUpdateLogs,
  },
  mutations: {
    // User
    [FETCH_USER]: (state, payload) => { state.fetchUser = payload },
    [FETCH_USERS]: (state, payload) => { state.fetchUsers = payload },
    [UPDATE_USER]: (state, payload) => { state.fetchUser = payload },
    [UPDATE_USERS_PASSWORD]: state => state.updateUsersPassword[0],
    [UPDATE_USERS_OUTCOME_PASSWORD]: state => state.updateUsersOutcomePassword[0],
    // UserBetHistory
    fetchUserBetHistory: (state, payload) => { state.userBetHistory = payload },

    updateParam(state, events) {
      // console.log('storeData', state.users)
      // console.log('t >> ', events.t) // target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      // state[events.t][0][events.e] = events.v
      state[events.t][events.e] = events.v
      // console.log('storeData role', state.users.role)
    },

    [UPDATE_USERS_ASSET]: (state, payload) => {
      if (['site', 'userid'].every(key => payload[key] === state.fetchUser[key])) {
        Object.assign(state.fetchUser, payload)
      }
    },
    [FETCH_USER_UPDATE_LOGS](state, payload) {
      state.fetchUserUpdateLogs = payload
    },
  },
  actions: {
    /* 회원관리 > 회원조회 > 회원상세 */
    async [FETCH_USER]({ commit, state }, payload) {
      console.log('[FETCH_USER] payload : ', payload)

      let fetchUser

      fetchUser = state.fetchUsers.find(item => item.site === payload.site && item.userid === payload.userid)

      if (fetchUser !== undefined && fetchUser !== null) {
        commit(FETCH_USER, fetchUser)
      } else {
        const { data } = await apolloClient.query({
          query: gql`
              query fetchUsers($site: String, $userid: String){
                fetchUsers(site: $site, userid: $userid) {
                  site
                  userid
                  username
                  nickname
                  recommendNickname
                  email
                  mobile
                  cash
                  cashCasino
                  point
                  signinTotal
                  betTotal
                  sportBettingAmount
                  casinoBettingAmount
                  slotBettingAmount
                  depositTotal
                  exchangeTotal
                  rank
                  role
                  memo
                  enrollIp
                  enrollDate
                  status
                  outcomePassword
                  accountBank
                  accountNumber
                  adminAccountBank
                  adminAccountNumber
                  adminAccountName
                  rateSportRolling
                  rateCasinoRolling
                  rateSlotRolling
                  createdAt
                  updatedAt
                  signinLastSite
                  signinLastIp
                  signinLastPlatform
                  signinLastDate
                },
              }
            `,
          variables: {
            site: payload.site,
            userid: payload.userid,
          },
          fetchPolicy: 'no-cache',
        })
        fetchUser = data.fetchUsers.find(item => item.site === payload.site && item.userid === payload.userid)
        commit(FETCH_USER, fetchUser)
      }
    },

    /* 회원관리 > 회원조회 */
    async [FETCH_USERS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchUsers($site: String, $userid: String){
            fetchUsers(site: $site, userid: $userid) {
              site
              userid
              username
              nickname
              recommendNickname
              email
              mobile
              cash
              point
              betTotal              
              sportBettingAmount
              casinoBettingAmount
              slotBettingAmount
              depositTotal
              exchangeTotal
              rank
              role
              enrollIp
              enrollDate
              status
              outcomePassword
              accountBank
              accountNumber
              adminAccountBank
              adminAccountNumber
              adminAccountName
              rateSportRolling
              rateCasinoRolling
              rateSlotRolling
              signinLastSite
              signinLastIp
              signinLastPlatform
              signinLastDate
              signinTotal
            },
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_USERS, data.fetchUsers)
    },

    async [UPDATE_USER]({ commit, state }, payload) {
      console.log('[UPDATE_USER] state : ', state)
      console.log('[UPDATE_USER] payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsers($site: String, $userid: String, $username: String, $nickname: String, $recommendNickname: String, $email: String, $mobile: String, $cash: Float, $point: Float, $rank: Float, $role: String, $memo: String, $enrollIp: String, $enrollDate: Date, $status: String, $outcomePassword: String, $accountBank: String, $accountNumber: String, $adminAccountBank: String, $adminAccountNumber: String, $adminAccountName: String, $rateSportRolling: Float, $rateCasinoRolling: Float, $rateSlotRolling: Float, $adminId: String, $adminIp: String){
            updateUsers(site: $site, userid: $userid, username: $username, nickname: $nickname, recommendNickname: $recommendNickname, email: $email, mobile: $mobile, cash: $cash, point: $point, rank: $rank, role: $role, memo: $memo, enrollIp: $enrollIp, enrollDate: $enrollDate, status: $status, outcomePassword: $outcomePassword, accountBank: $accountBank, accountNumber: $accountNumber, adminAccountBank: $adminAccountBank, adminAccountNumber: $adminAccountNumber, adminAccountName: $adminAccountName, rateSportRolling: $rateSportRolling, rateCasinoRolling: $rateCasinoRolling, rateSlotRolling: $rateSlotRolling, adminId: $adminId, adminIp: $adminIp) {
              site
              userid
              username
              nickname
              recommendNickname
              email
              mobile
              cash
              point
              rank
              role
              memo
              enrollIp
              enrollDate
              status
              outcomePassword
              accountBank
              accountNumber
              adminAccountBank
              adminAccountNumber
              adminAccountName
              rateSportRolling
              rateCasinoRolling
              rateSlotRolling
            }
          }
        `,
        variables: {
          /* TO-BE */
          site: state.fetchUser.site,
          userid: state.fetchUser.userid,
          username: state.fetchUser.username,
          nickname: state.fetchUser.nickname,
          recommendNickname: state.fetchUser.recommendNickname,
          email: state.fetchUser.email,
          mobile: state.fetchUser.mobile,
          cash: Number(state.fetchUser.cash),
          point: Number(state.fetchUser.point),
          rank: Number(state.fetchUser.rank),
          role: state.fetchUser.role,
          memo: state.fetchUser.memo,
          enrollIp: state.fetchUser.enrollIp,
          enrollDate: state.fetchUser.enrollDate,
          status: state.fetchUser.status,
          outcomePassword: state.fetchUser.outcomePassword,
          accountBank: state.fetchUser.accountBank,
          accountNumber: state.fetchUser.accountNumber,
          adminAccountBank: state.fetchUser.adminAccountBank,
          adminAccountNumber: state.fetchUser.adminAccountNumber,
          adminAccountName: state.fetchUser.adminAccountName,
          rateSportRolling: Number(state.fetchUser.rateSportRolling),
          rateCasinoRolling: Number(state.fetchUser.rateCasinoRolling),
          rateSlotRolling: Number(state.fetchUser.rateSlotRolling),
          adminId: payload.adminId,
          adminIp: payload.adminIp,
        },
        fetchPolicy: 'no-cache',
      })
      // console.log('updateUsers : ', data.updateUsers)
      commit(UPDATE_USER, data.updateUsers)
    },

    async [UPDATE_USERS_PASSWORD]({ commit, state }, payload) {
      // console.log('[UPDATE_USERS_PASSWORD] payload : ', state.users)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersPassword($site: String, $userid: String, $password: String, $adminId: String, $adminIp: String){
            updateUsersPassword(site: $site, userid: $userid, password: $password, adminId: $adminId, adminIp: $adminIp) {
              site
              userid
              password
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          password: payload.password,
          adminId: payload.adminId,
          adminIp: payload.adminIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USERS_PASSWORD, data.updateUsersPassword)
      // console.log('updateUsersPassword : ', data.updateUsersPassword)
    },

    async [UPDATE_USERS_OUTCOME_PASSWORD]({ commit, state }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersOutcomePassword($site: String, $userid: String, $outcomePassword: String, $adminId: String, $adminIp: String){
            updateUsersOutcomePassword(site: $site, userid: $userid, outcomePassword: $outcomePassword, adminId: $adminId, adminIp: $adminIp) {
              site
              userid
              outcomePassword
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          outcomePassword: payload.outcomePassword,
          adminId: payload.adminId,
          adminIp: payload.adminIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USERS_OUTCOME_PASSWORD, data.updateUsersOutcomePassword)
      // console.log('UPDATE_USERS_OUTCOME_PASSWORD : ', data.updateUsersOutcomePassword)
    },

    // UserBetHistory
    async [FETCH_USER_BET_HISTORY]({ commit }, payload) {
      // console.log('[FETCH_USER_BET_HISTORY] payload : ', payload)

      const fetchUserBetHistory = await apolloClient.query({
        query: FetchUserBetHistoryDocument,
        variables: {
          where: {
            userid: { in: payload.userid },
          },
        },
        fetchPolicy: 'no-cache',
      })
      // console.log('fetchUserBetHistory:', fetchUserBetHistory.data.fetchUserBetHistory)
      commit('fetchUserBetHistory', fetchUserBetHistory.data.fetchUserBetHistory)
    },

    /** ***********************************************************************************************
     *  USERS_ASSET
     ************************************************************************************************ */
    // 관리자 권한으로 포인트/캐시(스포츠)/캐시(카지노) 지급
    async [UPDATE_USERS_ASSET]({ commit, state }, payload) {
      let resultStatus = -1
      console.log('payload:::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersAsset($data: UsersAssetArgs!){
            updateUsersAsset(data: $data) {
              site
              userid
              username
              nickname
              recommendNickname
              cash
              cashCasino
              point
              memo
            }
          }
        `,
        variables: {
          data: {
            site: payload.site,
            userid: payload.userid,
            adminId: payload.adminId,
            adminIp: payload.adminIp,
            memo: payload.memo,
            ...(payload.cash !== undefined && { cash: payload.cash }),
            ...(payload.point !== undefined && { point: payload.point }),
          },
        },
        fetchPolicy: 'no-cache',
      })

      if (data) {
        commit(UPDATE_USERS_ASSET, data.updateUsersAsset)
        resultStatus = 1
      }
      return resultStatus
    },

    /** ***********************************************************************************************
     *  USER_UPDATE_LOGS
     ************************************************************************************************ */
    async [FETCH_USER_UPDATE_LOGS]({ commit, state }, payload) {
      // const param = {
      //   site: payload.site,
      //   userid: payload.userid,
      //   ...(payload.periodFrom !== undefined && { periodFrom: payload.periodFrom }),
      //   ...(payload.periodTo !== undefined && { periodTo: payload.periodTo }),
      // }
      // console.log('[FETCH_USER_UPDATE_LOGS] param : ', JSON.stringify(param))

      const { data } = await apolloClient.query({
        query: gql`
          query fetchUserUpdateLogs($site: String, $userid: String, $periodFrom: String, $periodTo: String){
            fetchUserUpdateLogs(site: $site, userid: $userid, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              site
              category
              categoryDetail
              adminid
              adminIp
              targetUserid
              tran
              asisContent
              tobeContent
              asisVal
              tobeVal
              memo
              createdAt
              updatedAt
            }
          } 
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          ...(payload.periodFrom !== undefined && { periodFrom: payload.periodFrom }),
          ...(payload.periodTo !== undefined && { periodTo: payload.periodTo }),
        },
        fetchPolicy: 'no-cache',
      })
      // console.log('[FETCH_USER_UPDATE_LOGS] fetchUserUpdateLogs : ', data.fetchUserUpdateLogs.length, '\n', JSON.stringify(data.fetchUserUpdateLogs))
      commit(FETCH_USER_UPDATE_LOGS, data.fetchUserUpdateLogs)
    },
  },
}

export default userStore
